import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Revs from "../components/reviews";

import SliderDrinks from "../components/slider-drinks";
import BlogModule from "../components/post-module";
import { useEffect } from "react";

const Home = ({ data, location }) => {
  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY >= 45) {
        document.querySelector(
          "#gatsby-focus-wrapper > header > div"
        ).style.top = "0px";
        document.querySelector(
          "#gatsby-focus-wrapper > header > div"
        ).style.position = "fixed";
        document.querySelector("main").style.marginTop = "45px";
      } else {
        document.querySelector(
          "#gatsby-focus-wrapper > header > div"
        ).style.top = "0px";
        document.querySelector(
          "#gatsby-focus-wrapper > header > div"
        ).style.position = "relative";
        document.querySelector("main").style.marginTop = "0px";
      }
      let mybtn = document.getElementById("back-to-top");
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        mybtn.style.display = "block";
      } else {
        mybtn.style.display = "none";
      }
    };
  });

  function b2b1() {
    return (
      <StaticImage
        src="../../images/b1.jpg"
        alt="shroom for bistro"
        placeholder="blurred"
        className="b2bimg"
        layout="constrained"
        height="875"
        width="500"
        style={{
          boxShadow: "5px 5px 20px rgba(23,19,43,.2)",
          borderRadius: "25px",
        }}
        formats={["auto", "webp", "avif"]}
      />
    );
  }
  function b2b2() {
    return (
      <StaticImage
        src="../../images/b2.jpg"
        alt="shroom for distributros"
        placeholder="blurred"
        className="b2bimg"
        layout="constrained"
        height="875"
        width="500"
        style={{
          boxShadow: "5px 5px 20px rgba(23,19,43,.2)",
          borderRadius: "25px",
        }}
        formats={["auto", "webp", "avif"]}
      />
    );
  }
  function b2b3() {
    return (
      <StaticImage
        src="../../images/b3.jpg"
        alt="shroom for offices"
        placeholder="blurred"
        className="b2bimg"
        layout="constrained"
        height="875"
        width="500"
        style={{
          boxShadow: "5px 5px 20px rgba(23,19,43,.2)",
          borderRadius: "25px",
        }}
        formats={["auto", "webp", "avif"]}
      />
    );
  }

  //const siteTitle = data.site.siteMetadata.title
  //const energyDrinks = data.allWpProduct.edges
  //const blogPosts = data.allWpPost.edges
  // console.log(blogPosts)
  return (
    <>
      <Layout data={data} location={location}>
        <Seo
          title="Shroom - the natural energy drink with cordyceps, lion's mane and l'theanine"
          description="Lion's Mane, Cordyceps, and L-Theanine: Natural Supplements for Cognitive Support, Immune Boosting, and Stress Reduction. Shop our range of Natural Energy Drinks, Apparel, and Accessories."
        />
        {/* Hero */}
        <div
          id="hwrap"
          className="container-fluid mush"
          style={{
            padding: "0",
            minHeight: "620px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div
            id="hero"
            className="mask container-fluid"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              width: "100%",
              minWidth: "100%",
              height: "100%",
              minHeight: "800px",
              justifyContent: "center",
            }}
          >
            <div
              className="col-8"
              style={{
                minHeight: "400px",
                display: "block",
                textAlign: "center",
              }}
            >
              <h1
                className="text-white mob-hero spec-h"
                style={{
                  textAlign: "center",
                  fontSize: "5em",
                  wordBreak: "break-word",
                }}
              >
                shroom drink
              </h1>
              <br />
              <h2
                className="text-white"
                style={{
                  textAlign: "center",
                  fontSize: "1.5em",
                  fontWeight: "800",
                  letterSpacing: "2px",
                }}
              >
                Fuel your body, feed your mind - adaptogen wellness drinks
              </h2>
              <p
                className="text-white"
                style={{
                  textAlign: "center",
                  margin: "auto",
                  maxWidth: "500px",
                  color: "#111",
                }}
              >
                <br />
                <br />
                <a
                  href="#mushroom-drinks"
                  className="btn btn-outline-light"
                  style={{ margin: "10px" }}
                >
                  learn more&nbsp;
                  <span role="img" aria-label="mushroom">
                    🍄
                  </span>
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://shop.shroom4you.com/"
                  className="btn btn-outline-light"
                  style={{ margin: "10px" }}
                >
                  visit shop&nbsp;
                  <span role="img" aria-label="shop">
                    🛒
                  </span>
                </a>
              </p>
              <br />
              <div
                style={{
                  padding: "2em",
                  textAlign: "center",
                  color: "white",
                }}
              >
                <a
                  id="scroll-to"
                  href="#mushroom-drinks"
                  style={{ fontSize: "15px" }}
                >
                  ⇩
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          id="mushroom-drinks"
          style={{
            width: "100%",
            padding: "0",
            backgroundColor: "#E8D3D0",
          }}
        >
          <br />
          <div className="row container-fluid" style={{ margin: "auto" }}>
            <div
              className="column col mx-auto"
              style={{ height: "750px", position: "relative" }}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://shop.shroom4you.com"
              >
                <div
                  className="home-mod"
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    padding: "1em",
                    margin: "1em",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "20px",
                      left: "auto",
                      padding: "1em",
                      color: "#1D1D1D",
                      zIndex: 2,
                      maxWidth: "362px",
                    }}
                  >
                    <h2 className="spec-h home-m">shroom shop</h2>
                    <p>
                      Probably the <span className="spec-h">#1 mushroom</span>{" "}
                      functional drink in Europe.
                    </p>
                    <br />
                    <p style={{ textAlign: "center", maxWidth: "100%" }}>
                      <button className="btn">
                        visit shop&nbsp;
                        <span role="img" aria-label="shopping">
                          🛒
                        </span>
                      </button>
                    </p>
                  </div>
                  <video
                    style={{
                      height: "650px",
                      boxShadow: "5px 5px 20px rgba(23,19,43,.2)",
                      borderRadius: "25px",
                      margin: "auto",
                      maxWidth: "100%",
                      objectFit: "cover",
                    }}
                    autoPlay="autoplay"
                    loop="loop"
                    muted
                    defaultmuted="true"
                    playsInline
                    onContextMenu={() => {
                      return false;
                    }}
                    loading="lazy"
                    src="https://admin.shroom4you.com/wp-content/uploads/2024/01/ANIMACJA_11.mp4"
                  ></video>
                </div>
              </a>
            </div>
            <div
              className="column col mx-auto mod-r"
              style={{ height: "750px" }}
            >
              <a href="#b2b">
                <div
                  className="home-mod"
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    padding: "1em",
                    margin: "1em",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "20px",
                      left: "auto",
                      padding: "1em",
                      color: "#FEFEFE",
                      zIndex: 2,
                      width: "100%",
                    }}
                  >
                    <h2 className="spec-h home-m" style={{ color: "white" }}>
                      shroom for B2B
                    </h2>
                    <p>
                      Explore our B2B offer of{" "}
                      <span className="spec-h">shroom drinks</span>.
                    </p>
                    <br />
                    <p style={{ textAlign: "center" }}>
                      <button className="btn">
                        learn more&nbsp;
                        <span role="img" aria-label="b2b">
                          ✨
                        </span>
                      </button>
                    </p>
                  </div>

                  <img
                    src="https://admin.shroom4you.com/wp-content/uploads/2024/01/shroomscriptions.jpg"
                    alt="shroom for b2b"
                    className="home-mod-img"
                    loading="lazy"
                    style={{
                      gridArea: "1/1",
                      width: "100%",
                      objectFit: "cover",
                      minHeight: "300px",
                      maxWidth: "100%",
                      boxShadow: "5px 5px 20px rgba(23,19,43,.2)",
                      borderRadius: "25px",
                    }}
                  />
                </div>
              </a>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://shop.shroom4you.com/contact/"
              >
                <div
                  className="home-mod"
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    padding: "1em",
                    margin: "1em",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "20px",
                      left: "auto",
                      padding: "1em",
                      color: "#FEFEFE",
                      zIndex: 2,
                      width: "100%",
                    }}
                  >
                    <h2 className="spec-h home-m" style={{ color: "white" }}>
                      Contact
                    </h2>
                    <p>
                      Lets's talk! Contact{" "}
                      <span className="spec-h">shroom</span> now!
                    </p>
                    <br />
                    <p style={{ textAlign: "center" }}>
                      <button className="btn">
                        contact us&nbsp;
                        <span role="img" aria-label="mushroom">
                          🍄
                        </span>
                      </button>
                    </p>
                  </div>

                  <img
                    src="https://admin.shroom4you.com/wp-content/uploads/2024/01/acc.jpg"
                    alt="contact shroom"
                    loading="lazy"
                    className="home-mod-img"
                    style={{
                      gridArea: "1/1",
                      width: "100%",
                      objectFit: "cover",
                      minHeight: "300px",
                      maxWidth: "100%",
                      boxShadow: "5px 5px 20px rgba(23,19,43,.2)",
                      borderRadius: "25px",
                    }}
                  />
                </div>
              </a>

              <div style={{ marginTop: "30px" }}> </div>
            </div>
          </div>
        </div>

        <div
          id="b2b"
          style={{
            width: "100%",
            padding: "2rem",
            paddingTop: "50px",
            marginBottom: "50px",
            backgroundColor: "#FCFAF6",
          }}
        >
          <h2
            style={{
              textAlign: "center",
              fontSize: "3rem",
              paddingTop: "25px",
            }}
          >
            <span className="spec-h">shroom</span> for B2B
          </h2>
          <div className="row" style={{ paddingTop: "50px" }}>
            <div className="col">
              {b2b1()}
              <h3
                className="b2bh"
                style={{
                  textAlign: "center",
                  color: "white",
                  marginTop: "-60px",
                  zIndex: "10",
                  position: "absolute",
                  width: "95%",
                  maxWidth: "500px",
                }}
              >
                <span className="spec-h">shroom</span> for HoReCa
              </h3>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                BAR RESTAURANT HOTEL BISTRO
              </p>
              <p>
                Addressing your offer to health-oriented and conscious clients?
                Bored of kombucha? Reach out for the special prices and sales
                support packages.
              </p>
            </div>
            <div className="col">
              {b2b2()}
              <h3
                className="b2bh"
                style={{
                  textAlign: "center",
                  color: "white",
                  marginTop: "-60px",
                  zIndex: "10",
                  position: "absolute",
                  width: "95%",
                  maxWidth: "500px",
                }}
              >
                <span className="spec-h">shroom</span> for distributors
              </h3>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                Partner with Us
              </p>
              <p>
                We couldn't do it all without you! Want to bring adaptogens and
                wellness closer to people? Searching for alcohol alternatives?
                Reach out to become our distribution partner. Flexible ordering,
                marketing materials and dedicated support will help your sales
                thrive!
              </p>
            </div>
            <div className="col">
              {b2b3()}
              <h3
                className="b2bh"
                style={{
                  textAlign: "center",
                  color: "white",
                  marginTop: "-60px",
                  zIndex: "10",
                  position: "absolute",
                  width: "95%",
                  maxWidth: "500px",
                }}
              >
                <span className="spec-h">shroom</span> for offices
              </h3>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                Delivered Right to You!
              </p>
              <p>
                Caring for your employees to the point that you don't want them
                eating and drinking unhealthy? Tired of caffeine and sugar
                crashes affecting your team's productivity? Worry not, dear
                friend—Shroom is here to take your company rituals to the next
                level.
              </p>
            </div>
          </div>
          <br />
          <p style={{ textAlign: "center" }}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://shop.shroom4you.com/contact/"
              className="btn"
            >
              contact us&nbsp;
              <span role="img" aria-label="mushroom">
                🍄
              </span>
            </a>
          </p>
        </div>

        <div
          className="mt-5 container-fuild has-text-centered"
          style={{
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            margin: "1em 0em",
            padding: "2em 1em",
          }}
        >
          <h2 style={{ textAlign: "center" }} className="spec-h">
            Grab Your shroom drinks now!
          </h2>
          <p style={{ textAlign: "center" }}>
            Natural supplements for cognitive support, immune boosting, and
            stress reduction.
          </p>
        </div>

        <SliderDrinks />
        <br />
        <br />
        <br />
        <br />
        <Revs />
        <br />
        <br />
        <BlogModule />
        <br />
        <p style={{ textAlign: "center" }}>
          <Link to="/blog/" className="btn btn-outline-dark mush">
            visit blog &nbsp;
            <span role="img" aria-label="mushroom">
              🍄
            </span>
          </Link>
        </p>
        <br />
        <br />
      </Layout>
    </>
  );
};

export default Home;

export const pageQuery = graphql`
  query GetEnergyDrinks {
    site {
      siteMetadata {
        title
      }
    }
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: 2
      filter: { language: { code: { eq: EN } } }
    ) {
      edges {
        node {
          id
          date(formatString: "DD.MM.YYYY")
          link
          title
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: TRACED_SVG
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          excerpt
          author {
            node {
              name
              avatar {
                url
              }
            }
          }
          categories {
            nodes {
              name
              uri
            }
          }
          tags {
            nodes {
              name
            }
          }
        }
      }
    }
  }
`;
